import {
  Box,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { DangerousComponentInjector } from '../DangerousComponentInjector';
import { CcTypography } from '../CcTypography';
import { IDictionary } from '../../types/otherData';

const useStyles = makeStyles({
  root: {
    margin: 0,
    padding: `0px 
    calc(var(--spacing-m) + 5px) 
    var(--spacing-m) 
    calc(var(--spacing-m) + 5px)`,
    wordBreak: 'break-word',
  },
});

type Props = {
  titleParams: IDictionary<any>;
  classes?: IDictionary<string>;
  title?: string;
  subtitle?: React.ReactNode;
};

export const RegularTitle = ({
  title,
  titleParams,
  subtitle,
  classes,
}: Props) => {
  const styles = useStyles();
  return (
    <MuiDialogTitle disableTypography className={cx(styles.root, classes?.root)}>
      <Box width="100%" textAlign="center">
        <DangerousComponentInjector
          component={CcTypography}
          text={title}
          fontWeight={600}
          variant="h2"
          params={titleParams}
          data-test="modal title"
        />
        {
          subtitle
        }
      </Box>
    </MuiDialogTitle>
  );
};
