import { apiRequest } from '../../utils/apiRequest';
import { toParams } from '../../utils/params';
import { USER_ROLES_TYPE, USER_STATUS_TYPE } from '../../applicationConstants';

export type GetUserList = {
    search?: string;
    start?:number;
    count?: number;
    status?: USER_STATUS_TYPE;
    role?: USER_ROLES_TYPE;
};
export const getUsersList = (
  data: GetUserList,
) => apiRequest(
  `user${toParams({
    start: data?.start,
    count: data?.count,
    name: data?.search,
    status: data?.status,
    role: data?.role,
  })}`,
  {
    method: 'GET',
  },
);

export const addUserRequest = (
  body: {
      fullName: string;
      email: string;
      role: string;
      location?: string;
      region?: string;
  },
) => apiRequest(
  'user',
  {
    method: 'POST',
    body,
  },
);

export type EditUserBody = {
    role: string;
    location?: string;
    region?: string;
    userId: string;
};

export const editUserRequest = (
  { userId, ...body }: EditUserBody,
) => apiRequest(
  `user/${userId}/edit`,
  {
    method: 'POST',
    body,
  },
);

export const setUserStatusRequest = (
  { userId, status }: {
        userId: string;
      status: string;
  },
) => apiRequest(
  `user/${userId}/status`,
  {
    method: 'POST',
    body: { status },
  },
);

export const resetPasswordRequest = () => apiRequest(
  'user/reset_password',
  {
    method: 'POST',
  },
);

export const getUserByEmail = (
  email: string,
) => apiRequest(
  `user/email/${email}`,
  {
    method: 'GET',
  },
);

export const getUserByIdRequest = (
  id: string,
) => apiRequest(
  `user/${id}`,
  {
    method: 'GET',
  },
);

export const changeUserEmail = ({
  userId,
  email,
}: {
    userId: string;
    email: string;
  }) => apiRequest(
  `user/${userId}/email`,
  {
    method: 'POST',
    body: { email },
  },
);
