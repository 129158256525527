import { AnyAction } from 'redux';

import {
  getUsersAction,
} from './actionTypes';
import { IBaseReducer, IPaginationResponse } from '../../types/otherData';
import { IUser } from '../../types/user';

export interface IUsersReducer extends IBaseReducer {
  data: Partial<IPaginationResponse<IUser>>;
}
const initialState: IUsersReducer = {
  data: { },
  isLoaded: false,
  error: null,
};

export function usersReducer(state = initialState, action: AnyAction): IUsersReducer {
  const { type } = action;
  switch (type) {
    case getUsersAction.SUCCESS: {
      const { payload: { data } } = action;
      return {
        ...state,
        isLoaded: true,
        data: {
          ...data,
          items: data.items,
        },
      };
    }
    case getUsersAction.FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
