import { defActionTypes } from '../../utils/defActionTypes';

export const configurationsActions = defActionTypes('get_configurations');
export const getInviteConfigurationsActions = defActionTypes('get_invite_configurations');
export const updateConfigurationsActions = defActionTypes('update_configurations_actions');
export const setUgaProductActions = defActionTypes('set_uga_product');
export const setGreatAmericaProgramActions = defActionTypes('set_ga_program');
export const getLenderConfigurationActions = defActionTypes('get_lender_configuration_actions');
export const deleteLenderConfigurationActions = defActionTypes('delete_lender_configuration_actions');

export const CLEAR_CONFIGURATIONS = 'CLEAR_CONFIGURATIONS';
