import { AnyAction } from 'redux';

import {
  getNetworkConfigurationAction,
  updateNetworkConfigurationAction,
} from './actionTypes';
import { INetworkConfigurationReducer } from './types';

const initialNetworkConfigurationState: INetworkConfigurationReducer = {
  data: undefined,
  status: 'initial',
  error: null,
};

export function networkConfigurationsReducer(
  state = initialNetworkConfigurationState,
  action: AnyAction,
): INetworkConfigurationReducer {
  const { payload, type } = action;
  switch (type) {
    case getNetworkConfigurationAction.REQUEST:
    case updateNetworkConfigurationAction.REQUEST:
      return {
        ...state,
        status: 'loading',
        error: null,
      };
    case getNetworkConfigurationAction.SUCCESS:
    case updateNetworkConfigurationAction.SUCCESS:
      return {
        ...state,
        data: payload.data,
        status: 'loaded',
      };
    case getNetworkConfigurationAction.FAILURE:
    case updateNetworkConfigurationAction.FAILURE:
      return {
        ...state,
        status: 'error',
        error: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
