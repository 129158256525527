import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Close24 from '../Icons/24/Close24';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: 'var(--spacing-m)',
    top: 'var(--spacing-m)',
    padding: 0,
    width: 32,
    height: 32,
  },
});

type Props = {
    onClose: () => void;
};

export const CloseButton = ({ onClose }: Props) => {
  const styles = useStyles();
  return (
    <IconButton
      data-test="close modal button. cross"
      aria-label="[button = close]"
      className={styles.closeButton}
      onClick={onClose}
    >
      <Close24
        color="rgba(50, 32, 126, 0.5)"
      />
    </IconButton>
  );
};
