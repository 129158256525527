import { apiRequest } from '../../utils/apiRequest';
import { IConfiguration, LenderConfiguration, SplititLenderConfig } from '../../types/lenderConfiguration';
import { toParams } from '../../utils/params';
import { LENDERS_TYPE } from '../../applicationConstants';

export const getConfigurationApiRequest = (params?: {location?: string}) => apiRequest(
  `configuration${toParams(params)}`,
  {
    method: 'GET',
  },
);

export const getInviteConfigurationRequest = (invite: string) => apiRequest(
  `configuration/invite${toParams({ invite })}`,
  {
    method: 'GET',
  },
);

export interface IUpdateConfigPayload extends Partial<
    Omit<IConfiguration, 'serviceLender' | 'lenderTiers'>
> {
    serviceLender?: LENDERS_TYPE | null;
    lenderTiers?: IConfiguration['lenderTiers'];
    splitit?: IConfiguration['splitit'];
}

export const updateConfigurationApiRequest = (body: IUpdateConfigPayload, locationId?: string) => apiRequest(
  `configuration${toParams({ location: locationId })}`,
  {
    method: 'POST',
    body,
  },
);

export type UgaProductRequestBody = {
  financing: string;
  type: string;
};
export const setUgaProductApiRequest = (body: UgaProductRequestBody, locationId?: string) => apiRequest(
  `configuration/uga/product${toParams({ location: locationId })}`,
  {
    method: 'POST',
    body: {
      uga_financing: body.financing,
      uga_type: body.type,
    },
  },
);

export const setGreatAmericaProgramApiRequest = (body: {program: string}, locationId?: string) => apiRequest(
  `configuration/ga/program${toParams({ location: locationId })}`,
  {
    method: 'POST',
    body,
  },
);

export const getLenderConfigurationApiRequest = ({
  lender,
  locationId,
}:{lender: string, locationId ? : string }) => apiRequest(
  `configuration/${lender}${toParams({ location: locationId })}`,
  {
    method: 'GET',
  },
);

export type UpdateNetworkConfiguration = {
    enablePublicLink: boolean;
    publicLinkAssignee?: string;
};
export const updateNetworkConfigurationApiRequest = ({
  enablePublicLink,
  publicLinkAssignee,
}: UpdateNetworkConfiguration) => apiRequest(
  'configuration/network',
  {
    method: 'POST',
    body: {
      enablePublicLink,
      publicLinkAssignee,
    },
  },
);

export const getNetworkConfigurationApiRequest = () => apiRequest(
  'configuration/network',
  {
    method: 'GET',
  },
);

export const updateLenderConfigurationApiRequest = ({
  lender,
  locationId,
  lenderConfig,
}:{
    lender: string,
    lenderConfig: LenderConfiguration | SplititLenderConfig | null,
    locationId ? : string
}) => apiRequest(
  `configuration/${lender}${toParams({ location: locationId })}`,
  {
    method: 'POST',
    body: {
      config: lenderConfig,
    },
  },
);

export const deleteLenderConfigurationApiRequest = ({
  lender,
  locationId,
}:{
    lender: string,
    locationId ? : string
}) => apiRequest(
  `configuration/${lender}${toParams({ location: locationId })}`,
  {
    method: 'DELETE',
  },
);
