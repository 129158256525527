import { joinUrlParts } from './utils/urlTools';
import { defRoute } from './utils/defRoute';

export const ROOT = defRoute('/');
export const NOT_FOUND = defRoute('/404');
export const ADMIN = defRoute('/admin');
export const MY_ACCOUNT = defRoute('/myAccount');
export const CUSTOMER = defRoute('/customer/:customerId');

export const SUPPORT_NETWORKS = defRoute('/supportNetworks');
export const SUPPORT_PORTAL = defRoute(joinUrlParts(SUPPORT_NETWORKS.pattern, 'portal'));
// eslint-disable-next-line max-len
export const SUPPORT_CHANGE_NETWORK_PORTAL = defRoute(joinUrlParts(SUPPORT_NETWORKS.pattern, 'follow/:network/:submission'));
export const SUPPORT_PORTAL_APPLICANT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'applicant'));
export const SUPPORT_PORTAL_APPLICATION = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'application'));
export const SUPPORT_PORTAL_MERCHANT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'merchant'));
export const SUPPORT_PORTAL_MANAGEMENT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'management'));
export const SUPPORT_LENDERS_MANAGEMENT = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'lenders'));
export const SUPPORT_PORTAL_TRANSACTIONS = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'transactions'));
export const SUPPORT_PORTAL_USERS = defRoute(joinUrlParts(SUPPORT_PORTAL.pattern, 'users'));

export const MANAGE_USERS = defRoute(joinUrlParts(ADMIN.pattern, 'manageUsers'));
export const MANAGE_LENDERS = defRoute(joinUrlParts(ADMIN.pattern, 'manageLenders'));
export const MANAGE_LOCATION_LENDERS = defRoute(joinUrlParts(MANAGE_LENDERS.pattern, '/:locationId'));
export const BANK_RECONCILIATION = defRoute(joinUrlParts(ADMIN.pattern, 'bankReconciliation'));
export const FINANCING_REPORTS = defRoute(joinUrlParts(BANK_RECONCILIATION.pattern, 'financial'));
export const SERVICING_REPORTS = defRoute(joinUrlParts(BANK_RECONCILIATION.pattern, 'servicing'));

export const MANAGE_APPLICATIONS = defRoute(joinUrlParts(ADMIN.pattern, 'manageApplications'));
export const MANAGE_LENDER_APPLICATIONS = defRoute(joinUrlParts(MANAGE_APPLICATIONS.pattern, 'lender'));
export const MANAGE_LENDER_APPLICATION = defRoute(joinUrlParts(MANAGE_APPLICATIONS.pattern, 'lender/:submission'));

export const MANAGE_NETWORK = defRoute(joinUrlParts(ADMIN.pattern, 'manageNetwork'));
export const MANAGE_NETWORK_LOCATIONS = defRoute(joinUrlParts(MANAGE_NETWORK.pattern, 'locations'));
export const MANAGE_NETWORK_REGIONS = defRoute(joinUrlParts(MANAGE_NETWORK.pattern, 'regions'));
export const MANAGE_NETWORK_GENERAL = defRoute(joinUrlParts(MANAGE_NETWORK.pattern, 'general'));

export const ERROR_PAGE = defRoute(joinUrlParts(ROOT.pattern, 'error'));

export const SPLIT_IT_HOW_IT_WORKS = defRoute(joinUrlParts(ROOT.pattern, 'how-it-works'));
export const SPLIT_IT = defRoute(joinUrlParts(ROOT.pattern, 'splitit'));
export const SPLIT_IT_APPLICATION = defRoute(joinUrlParts(ROOT.pattern, 'splitit/application'));
export const SPLIT_IT_CARD_SETUP = defRoute(joinUrlParts(ROOT.pattern, 'splitit/card-setup'));
export const SPLIT_IT_SUCCESS = defRoute(joinUrlParts(ROOT.pattern, 'splitit/success'));
export const SPLIT_IT_FAILURE = defRoute(joinUrlParts(ROOT.pattern, 'splitit/failure'));
export const SPLIT_IT_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'splitit/error'));

export const TOKEN_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'token_error'));
export const DISABLED_LENDER_ERROR = defRoute(joinUrlParts(ROOT.pattern, 'disabled_lender_error'));

export const GUEST_AUTHORIZATION = defRoute(joinUrlParts(ROOT.pattern, 'guest_auth'));

export const IMPORT_ACCOUNT = defRoute(joinUrlParts(ROOT.pattern, ''));
export const IMPORT_ACCOUNT_SUCCESS = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'success'));
export const IMPORT_ACCOUNT_TOO_MANY = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'too-many'));
export const IMPORT_ACCOUNT_FAILURE = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'failure'));
export const IMPORT_ACCOUNT_CONFIRM = defRoute(joinUrlParts(IMPORT_ACCOUNT.pattern, 'confirm'));
