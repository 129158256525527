import {
  getNetworkConfigurationApiRequest,
  UpdateNetworkConfiguration,
  updateNetworkConfigurationApiRequest,
} from 'store/apiRequests';
import {
  getNetworkConfigurationAction,
  updateNetworkConfigurationAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IConfiguration } from '../../types/lenderConfiguration';

export const updateNetworkConfiguration = defThunkRequest<UpdateNetworkConfiguration, IConfiguration>({
  actionTypes: updateNetworkConfigurationAction,
  thunkSteps: [
    async ({ data }) => {
      return updateNetworkConfigurationApiRequest(data);
    },
  ],
});

export const getNetworkConfiguration = defThunkRequest<undefined, IConfiguration>({
  actionTypes: getNetworkConfigurationAction,
  thunkSteps: [
    async () => {
      return getNetworkConfigurationApiRequest();
    },
  ],
});
