import React from 'react';
import { Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import ColorAlarm24 from 'components/Icons/24/ColorAlarm24';
import { CcTypography } from '../../CcTypography';
import { ProgressLine } from './ProgressLine';
import ColoredInfo32 from '../../Icons/Obsolete/ColoredInfo32';
import Check24 from '../../Icons/24/Check24';

type Props = {
    steps: Array<{
        title: string;
        id: string;
    }>;
    activeStep: string;
    status: 'error' | 'warning' | 'default'
};

const getColorByState = ({ status, isActive }: any) => {
  if (!isActive) return 'rgba(9, 2, 36, 0.7)';

  if (status === 'error') {
    return 'rgba(210, 46, 44, 1)';
  }

  if (status === 'warning') {
    return 'rgba(235, 122, 40, 1)';
  }

  return '#32207E';
};

const ProgressElementIcon = ({ status, isFinished }: any) => {
  if (isFinished) return <Check24 width={20} height={20} color="white" />;

  if (status === 'error') {
    return <ColorAlarm24 width={20} height={20} color="white" />;
  }

  if (status === 'warning') {
    return (
      <ColoredInfo32 />
    );
  }

  return (
    <div style={{
      width: 6,
      height: 6,
      background: 'white',
      borderRadius: 40,
    }}
    />
  );
};

const useProgressDotStyles = makeStyles(() => createStyles({
  dot: {
    background: ({ isActivated }: any) => (isActivated ? '#2800F0' : 'rgba(230, 234, 237, 1)'),
    width: 32,
    height: 32,
    boxShadow: ({
      isActive,
    }: any) => (isActive
      ? '0px 0px 0px 8px rgba(40, 0, 240, 0.04), 0px 0px 0px 4px rgba(40, 0, 240, 0.08)'
      : ''),
    borderRadius: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: 24,
    position: 'relative',
    zIndex: 1,
  },
  failedDot: {
    background: () => 'rgba(210, 46, 44, 1)',
    boxShadow: () => 'none',
    fontSize: 20,
  },
  warningDot: {
    background: () => 'none',
    boxShadow: () => 'none',
    fontSize: 20,
  },
  line: {
    width: '50%',
    border: '2px solid',
    position: 'absolute',
    borderColor: '#E6EAED',
    bottom: 14,
  },
  activeLine: {
    borderColor: 'rgb(40, 0, 240)',
    background: 'rgb(40, 0, 240)',
  },
}));

const ProgressElement = ({
  title,
  isActive,
  status,
  isFinished,
}: {
    title: string;
    isActive: boolean;
    isFinished: boolean;
    status?: Props['status']}) => {
  const styles = useProgressDotStyles({
    isActive,
    isActivated: isActive || isFinished,
  });
  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <CcTypography
        variant={isActive ? 'subtitle2' : 'body2'}
        customColor={getColorByState({ status, isActive })}
        style={{ whiteSpace: 'nowrap' }}
        data-test={title}
      >
        {title}
      </CcTypography>
      <Box
        marginTop="16px"
        className={cx(
          styles.dot,
          status === 'warning' && styles.warningDot,
          status === 'error' && styles.failedDot)}
      >
        <ProgressElementIcon
          isFinished={isFinished}
          status={status}
        />
      </Box>
      <Box width="100%" position="relative">
        <ProgressLine filled={isActive || isFinished} left />
        <ProgressLine filled={isFinished} right />
      </Box>
    </Box>
  );
};

export const ModalProgressBar = ({
  steps,
  activeStep,
  status = 'default',
}: Props) => {
  const activeElementIndex = steps.findIndex((x) => x.id === activeStep);
  return (
    <Box
      display="flex"
      position="relative"
      padding="0 40px"
    >
      {steps.map((x, index) => (
        <ProgressElement
          key={x.id}
          title={x.title}
          isActive={activeElementIndex === index}
          isFinished={activeElementIndex > index}
          status={activeElementIndex === index ? status : undefined}
        />
      ))}
      <ProgressLine width="40px" filled left />
      <ProgressLine width="40px" right />
    </Box>
  );
};
