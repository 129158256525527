import {
  getUsersList,
  addUserRequest,
  setUserStatusRequest,
  editUserRequest,
  EditUserBody,
  getUserByEmail,
  changeUserEmail,
  getUserByIdRequest,
  GetUserList,
} from 'store/apiRequests';
import {
  getUsersAction,
  addUserAction,
  setUserStatusAction,
  editUserAction,
  getUserByEmailAction,
  getUserByIdAction, changeUserEmailAction,
} from './actionTypes';
import { defThunkRequest } from '../../utils/defAsyncAction';
import { IUser } from '../../types/user';

export const getUsersThunk = defThunkRequest<GetUserList>({
  actionTypes: getUsersAction,
  thunkSteps: [
    ({ data }) => getUsersList(data),
  ],
});

export const addUserThunk = defThunkRequest({
  actionTypes: addUserAction,
  silentError: (resp) => resp.raw.status === 409,
  thunkSteps: [
    ({ data }: any) => addUserRequest(data),
  ],
});
export const editUserThunk = defThunkRequest<EditUserBody>({
  actionTypes: editUserAction,
  thunkSteps: [
    ({ data }) => editUserRequest(data),
  ],
});

export const setUserStatusThunk = defThunkRequest({
  actionTypes: setUserStatusAction,
  thunkSteps: [
    ({ data }) => setUserStatusRequest(data),
  ],
});

export const getUserByEmailThunk = defThunkRequest<{email: string}, IUser>({
  actionTypes: getUserByEmailAction,
  thunkSteps: [
    ({ data: { email } }) => getUserByEmail(email),
  ],
});

export const getUserByIdThunk = defThunkRequest<{id: string}, IUser>({
  actionTypes: getUserByIdAction,
  thunkSteps: [
    ({ data: { id } }) => getUserByIdRequest(id),
  ],
});

export const changeUserEmailThunk = defThunkRequest<{email: string, userId: string}>({
  actionTypes: changeUserEmailAction,
  thunkSteps: [
    ({ data }) => changeUserEmail(data),
  ],
});
